import styled, { keyframes } from 'styled-components'
import Image from '../image'

const PageLoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

const Logo = styled(Image)`
  animation: ${rotate} 1s infinite;
`

export function PageLoader() {
  return (
    <PageLoaderWrap>
      <Logo src={'walnut.svg'} />
    </PageLoaderWrap>
  )
}
