import styled from 'styled-components'
import Image from '../image'

const Magnifier = styled(Image)`
  background: rgba(255, 255, 255, 0.8);
  padding: 1.5rem;
  border-radius: 9999px;
  width: 100px;
  height: auto;
  opacity: 0;
  transition: opacity 500ms;
  cursor: pointer;
`

export function MagnifierIcon() {
  return <Magnifier src={'magnifier.svg'} />
}
