import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { createBrowserHistory } from 'history'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Route, HashRouter as Router, Switch } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import WebFont from 'webfontloader'
import PageLoader from './design-system/page-loader'
import ScrollToTop from './design-system/scroll-to-top'
import Accommodation from './views/accommodation'
import Almond from './views/almond'
import Cafe from './views/cafe'
import CafeSpaces from './views/cafe-spaces'
import Culture from './views/culture'
import Hazelnut from './views/hazelnut'
import Homepage from './views/homepage'
import Pistachio from './views/pistachio'
import Walnut from './views/walnut'

ReactGA.initialize('G-C71R6TBMWV')
ReactGA.pageview(window.location.pathname + window.location.search)

export const history = createBrowserHistory()

export default function App() {
  const [isPageReady, setIsPageReady] = useState(false)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Amatic SC', 'Roboto'],
      },
      active: () => {
        setIsPageReady(true)
      },
    })
  }, [])

  if (!isPageReady) {
    return <PageLoader />
  }

  return (
    <Router>
      <ScrollToTop />
      <Content>
        <GlobalStyle />
        <Switch>
          <Route path={'/cafe-spaces'}>
            <CafeSpaces />
          </Route>
          <Route path={'/cafe'}>
            <Cafe />
          </Route>
          <Route path={'/culture'}>
            <Culture />
          </Route>
          <Route exact={true} path={'/accommodation'}>
            <Accommodation />
          </Route>
          <Route path={'/accommodation/hazelnut'}>
            <Hazelnut />
          </Route>
          <Route path={'/accommodation/pistachio'}>
            <Pistachio />
          </Route>
          <Route path={'/accommodation/walnut'}>
            <Walnut />
          </Route>
          <Route path={'/accommodation/almond'}>
            <Almond />
          </Route>
          <Route exact={true} path={'/'}>
            <Homepage />
          </Route>
        </Switch>
      </Content>
    </Router>
  )
}

const Content = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 1rem;

  @media only screen and (max-width: 800px) {
    padding: 0.5rem;
  }

  @media only screen and (max-width: 350px) {
    padding: 0;
  }
`

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`
