import styled from 'styled-components'
import Image from '../image'
import Title from '../title'

export const HeaderLabel = styled.div`
  width: 50%;
  display: flex;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  p {
    margin-top: 0;
  }

  @media only screen and (max-width: 640px) {
    text-align: left;
    align-items: flex-start;
    margin-left: 0;
    width: 100%;
  }
`

export const BackLink = styled.div`
  display: flex;
`

export const Header = styled.div`
  display: flex;
  width: 70%;
  margin: auto;

  a {
    text-decoration: none;
    white-space: nowrap;
  }

  @media only screen and (max-width: 970px) {
    width: 100%;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

export const StyledArrow = styled(Image)`
  width: 20px;
  margin-right: 1rem;
`

export const Content = styled.div`
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`

export const Tabs = styled.div`
  margin: 2rem 0;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
`

export const TabTitle = styled(Title)<{ active: boolean }>`
  cursor: pointer;
  margin-right: 2rem;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};

  :hover {
    text-decoration: underline;
  }
`
