import styled from 'styled-components'
import BackToHomepageCross from '../../design-system/back-to-homepage-cross'
import DividerSection from '../../design-system/divider-section'
import GalleryPageTemplate from '../../design-system/gallery-page-template'
import PhotoTiles from '../../design-system/photo-tiles'
import About from './components/about'

const BUILD_IMAGES = [
  'cafe-build/6.jpg',
  'cafe-build/1.jpg',
  'cafe-build/7.jpg',
  'cafe-build/10.jpg',
  'cafe-build/4.jpg',
  'cafe-build/5.jpg',
  'cafe-build/2.jpg',
  'cafe-build/8.jpg',
  'cafe-build/9.jpg',
]

const NOW_IMAGES = [
  'kavarna/12.jpg',
  'kavarna/11.jpg',
  'kavarna/1.jpg',
  'kavarna/13.jpg',
  'kavarna/2.jpg',
  'kavarna/7.jpg',
  'kavarna/9.jpg',
  'kavarna/10.jpg',
  'kavarna/6.jpg',
  'kavarna/4.jpg',
  'kavarna/5.jpg',
  'kavarna/3.jpg',
]

const StyledContent = styled.div`
  padding: 6rem 0;
`

export function CafeSpaces() {
  return (
    <StyledContent>
      <BackToHomepageCross />
      <About />
      <DividerSection />
      <GalleryPageTemplate images={BUILD_IMAGES} title="fotky z výstavby" />
      <DividerSection />
      <PhotoTiles images={NOW_IMAGES} title="nyní" />
    </StyledContent>
  )
}
