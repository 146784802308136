import styled from 'styled-components'
import BackToHomepageCross from '../../design-system/back-to-homepage-cross'
import Coffee from '../../modules/coffee'
import OurSweets from '../../modules/our-sweets'
import Offers from '../../modules/offers'

const StyledContent = styled.div`
  padding: 6rem 0;
`

export function Cafe() {
  return (
    <StyledContent>
      <BackToHomepageCross />
      <Coffee />
      <Offers />
      <OurSweets />
    </StyledContent>
  )
}
