import styled from 'styled-components'
import Image from '../../../design-system/image'
import Title from '../../../design-system/title'

const StyledAbout = styled.div`
  display: flex;
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    width: 80%;

    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 930px) {
    width: 100%;
  }

  @media only screen and (max-width: 825px) {
    width: 60%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`

const Text = styled.div`
  text-align: justify;
`

const BaristaImage = styled(Image)`
  width: 100%;
`

const StyledTitle = styled(Title)`
  margin: 0 0 2rem 0;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  gap: 0px 4rem;

  p {
    text-align: justify;
  }

  @media only screen and (max-width: 825px) {
    grid-template-columns: 1fr;

    button {
      margin-bottom: 2rem;
    }
  }
`

export default function About() {
  return (
    <StyledAbout id="about">
      <Content>
        <div>
          <StyledTitle fontSize="3rem">&quot;Jak jsme se rozlouskli?&rdquo;</StyledTitle>
          <Text>
            <p>
              V roce 2016 to začalo touhou zřídit v našem malém městě kulturní prostor,
              který by přinesl jiný směr.
            </p>

            <p>
              Chybělo nám tu místo pro setkávání v klidném a příjemném prostředí, s
              nabídkou výborné kávy, lahodných dezertů, chutných snídaní, popř. i lehkého
              oběda, který si u nás bez obav dopřejete.
            </p>

            <p>
              Nejsme pouze kavárna, jsme také prostor, který nabízí kulturu a ubytování.
            </p>
          </Text>
        </div>
        <div>
          <BaristaImage src={'walnut.jpg'} />
        </div>
      </Content>
    </StyledAbout>
  )
}
