import styled from 'styled-components'
import Title from '../../design-system/title'
import Image from '../../design-system/image'

const StyledCoffee = styled.div`
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    width: 80%;

    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 930px) {
    width: 100%;
  }

  @media only screen and (max-width: 825px) {
    width: 60%;
    padding-top: 4rem;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 0px 4rem;

  p {
    text-align: justify;
  }

  @media only screen and (max-width: 825px) {
    grid-template-columns: 1fr;
  }
`

const CoffeeImage = styled(Image)`
  width: 100%;
`

const CoffeeControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 825px) {
    margin-bottom: 3rem;
  }
`

const CoffeeLogo = styled(Image)`
  width: 80px;
  height: auto;
  margin-right: 1rem;
`

const StyledLink = styled.a`
  background-color: black;
  text-decoration: none;
  font-family: 'Amatic SC';
  color: white !important;
  padding: 0.8rem 1rem;
  font-size: 2rem;
`

export function Coffee() {
  return (
    <StyledCoffee id="coffee">
      <Content>
        <div>
          <Title fontSize="3rem">Na mlýnku</Title>
          <p>
            Skvělá výběrová káva od českých pražíren, kterou se snažíme obměňovat, abychom
            Vás dokázali více vtáhnout do kávového světa. Při výběru kávy do našeho mlýnku
            si dáváme záležet, proto u nás najdete tu nejlepší kávu z tuzemských pražíren,
            která nám padla do oka.
          </p>
          <CoffeeControls>
            <CoffeeLogo src="doubleshot.png" />
            <StyledLink href="https://www.doubleshot.cz/cs">zjistit více</StyledLink>
          </CoffeeControls>
        </div>
        <div>
          <CoffeeImage src={'dobroty/3.jpg'} />
        </div>
      </Content>
    </StyledCoffee>
  )
}
