import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Image from '../../design-system/image'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  padding: 1rem;
`

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const MobileMenuIcon = styled(Image)`
  width: 50px;
  height: 50px;
  z-index: 999999;
  cursor: pointer;
`

const MobileCloseMenuIcon = styled(Image)`
  width: 50px;
  height: 50px;
  z-index: 999999;
  cursor: pointer;
`

const MobileNavigationList = styled.ul`
  padding: 0;
  list-style: none;
  text-align: center;
  cursor: pointer;

  li {
    color: white;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3rem;
    transition: opacity 500ms;
    font-family: 'Amatic SC';

    :hover {
      opacity: 0.6;
    }
  }
`

const MobileOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function Navigation() {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const NAVIGATION_OPTIONS = [
    {
      title: 'o nás',
      link: '/cafe-spaces',
    },
    {
      title: 'kavárna',
      link: '/cafe',
    },
    {
      title: 'ubytování v ořechu',
      link: '/accommodation',
    },
    {
      title: 'kultura',
      link: '/culture',
    },
    {
      title: 'kontakt',
      link: '#contact',
      anchorLink: true,
    },
  ]

  return (
    <Nav>
      {!showMobileMenu && (
        <MobileMenuIcon src={'menu_draw.svg'} onClick={() => setShowMobileMenu(true)} />
      )}
      {showMobileMenu && (
        <MobileCloseMenuIcon
          src={'cross_draw.svg'}
          onClick={() => setShowMobileMenu(false)}
        />
      )}

      {showMobileMenu && (
        <MobileOverlay onClick={() => setShowMobileMenu(false)}>
          <MobileNavigationList>
            {NAVIGATION_OPTIONS.map((option, index) => {
              if (option.anchorLink) {
                return (
                  <StyledAnchorLink key={index} href={option.link}>
                    <li>{option.title}</li>
                  </StyledAnchorLink>
                )
              }
              return (
                <StyledLink key={index} to={option.link}>
                  <li>{option.title}</li>
                </StyledLink>
              )
            })}
          </MobileNavigationList>
        </MobileOverlay>
      )}
    </Nav>
  )
}
