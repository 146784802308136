import { SplideSlide, Splide as SplideWrap } from '@splidejs/react-splide'
import styled from 'styled-components'
import Button from '../../design-system/button'
import Image from '../../design-system/image'

const SPLIDE_SETTINGS = {
  type: 'fade',
  perPage: 1,
  focus: 'center',
  cover: true,
  heightRatio: 0.5,
  autoplay: true,
  rewind: true,
  speed: 600,
  height: 650,
}

const ImageWrap = styled.div`
  position: relative;
  height: fit-content;
  margin-bottom: 10rem;

  @media only screen and (max-width: 650px) {
    margin-bottom: 4rem;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
`

const Logo = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 20%;
  height: auto;

  @media only screen and (max-width: 800px) {
    width: 30%;
  }

  @media only screen and (max-width: 650px) {
    width: 40%;
  }

  @media only screen and (max-width: 450px) {
    top: 30%;
    width: 50%;
  }
`

const StyledButton = styled(Button)`
  z-index: 999;
  margin: 0 0.5rem;

  @media only screen and (max-width: 450px) {
    text-align: center;
    margin-bottom: 1rem;
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  font-family: 'Amatic SC';
  color: white;
  padding: 0.54rem 0.9rem;
  font-size: 2rem;
  border: 2px solid white;
  background-color: transparent;
  text-decoration: none;
  margin: 0 0.5rem;
  line-height: 2.5rem;

  @media only screen and (max-width: 450px) {
    width: fit-content;

    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

const Buttons = styled.div`
  width: 100%;
  z-index: 999;
  position: absolute;
  bottom: 3rem;
  left: 0;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

const IMAGES = [
  'slideshow/1.jpg',
  'slideshow/2.jpg',
  'slideshow/3.jpg',
  'slideshow/4.jpg',
  'slideshow/5.jpg',
  'slideshow/7.jpg',
  'slideshow/8.jpg',
]

export function ImageOverlaySection() {
  return (
    <ImageWrap>
      <SplideWrap options={SPLIDE_SETTINGS}>
        {IMAGES.map((src, index) => {
          return (
            <SplideSlide key={index}>
              <Image src={src} />
            </SplideSlide>
          )
        })}
      </SplideWrap>
      <Logo src={'logo_white.svg'} />
      <Buttons>
        <StyledButton href="#contact" appearance="simple" withAnchor={true}>
          Rezervujte si stůl
        </StyledButton>
        <StyledLink href="https://www.facebook.com/kafeorech/posts/pfbid02jkdp8a1WH135ZczimAFxpKWhTF1oQQredqyH3Q3YYWKy7CjcGWj3BYiTxDnDaGi1l">
          menu
        </StyledLink>
        <StyledLink href="https://www.facebook.com/kafeorech/events">program</StyledLink>
      </Buttons>
      <Overlay />
    </ImageWrap>
  )
}
