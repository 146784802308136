import styled from 'styled-components'
import Image from '../image'

export const BackLink = styled.div`
  display: flex;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;

  a {
    text-decoration: none;
    white-space: nowrap;
  }

  @media only screen and (max-width: 970px) {
    width: 100%;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`

export const StyledArrow = styled(Image)`
  width: 20px;
  margin-right: 1rem;
`
