import styled from 'styled-components'
import Image from '../../design-system/image'

const StyledFooter = styled.div`
  position: relative;
  background-size: cover;
  background-position: 50% 20%;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  padding: 2rem;

  @media only screen and (max-width: 850px) {
    padding: 1rem;
  }
`

const Logo = styled(Image)`
  width: 150px;
  margin-bottom: 1rem;
`

const Address = styled.div`
  line-height: 1rem;

  p {
    margin: 0;
  }
`

const OpeningHours = styled.div`
  ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }
`

const ContactText = styled.span`
  font-weight: 500;
  font-size: 1.1rem;
`

const Day = styled.span`
  display: inline-block;
  width: 60px;
`

const Services = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px 3rem;
  grid-template-areas: '.';
  margin-bottom: 1rem;

  a {
    color: black;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-template-areas: '.';
    gap: 2rem 3rem;
    margin: auto;
    margin-bottom: 3rem;
    font-size: 1rem;

    ${ContactText} {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
`

const Socials = styled.div`
  img {
    opacity: 0.8;
    width: 50px;
    margin: 0 1rem;
    transition: opacity 500ms;

    :hover {
      opacity: 0.5;
    }
  }

  @media only screen and (max-width: 850px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const ContactStuff = styled.div`
  align-items: center;
  width: 50%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 3rem;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`

const Phone = styled.a`
  margin-left: 0.5rem;
`

const TextSeparator = styled.span`
  margin: 0 0.5rem;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`

export function Footer() {
  return (
    <>
      <StyledFooter id="contact">
        <Services>
          <div>
            <ContactText>
              <span>Rezervuj si stůl:</span>
              <Phone href="tel:+420775544565">+420&nbsp;775&nbsp;544&nbsp;565</Phone>
            </ContactText>
          </div>
          <div>
            <ContactText>
              <span>Kontakt pro kulturu:</span>
              <Phone href="tel:+420602446362">+420&nbsp;602&nbsp;446&nbsp;362</Phone>
              <TextSeparator>/</TextSeparator>
              <a href="mailto:hruskova@kafeorech.cz">hruskova@kafeorech.cz</a>
            </ContactText>
          </div>
          <div>
            <ContactText>
              <span>Objednej si ubytování:</span>
              <Phone href="tel:+420602446362">+420&nbsp;602&nbsp;446&nbsp;362</Phone>
              <TextSeparator>/</TextSeparator>
              <a href="mailto:ubytovani.kafeorech@gmail.com">
                ubytovani.kafeorech@gmail.com
              </a>
            </ContactText>
          </div>
        </Services>
        <ContactStuff>
          <div>
            <Logo src="logo_black.svg" />
            <Address>
              <div>
                <p>Poříčská 1612</p>
                <p>295 01 Mnichovo Hradiště</p>
              </div>
            </Address>
          </div>
          <Socials>
            <a href="https://www.facebook.com/kafeorech/">
              <Image src="facebook.svg" />
            </a>
            <a href="https://www.instagram.com/kafeorech/">
              <Image src="instagram.svg" />
            </a>
          </Socials>
          <OpeningHours>
            <ul>
              <li>
                <Day>pondělí</Day>
                <span>8:00 - 19:00</span>
              </li>
              <li>
                <Day>úterý</Day>
                <span>8:00 - 19:00</span>
              </li>
              <li>
                <Day>středa</Day>
                <span>8:00 - 19:00</span>
              </li>
              <li>
                <Day>čtvrtek</Day>
                <span>8:00 - 19:00</span>
              </li>
              <li>
                <Day>pátek</Day>
                <span>8:00 - 22:00</span>
              </li>
              <li>
                <Day>sobota</Day>
                <span>9:00 - 22:00</span>
              </li>
              <li>
                <Day>neděle</Day>
                <span>9:00 - 19:00</span>
              </li>
            </ul>
          </OpeningHours>
        </ContactStuff>
      </StyledFooter>
    </>
  )
}
