import AccommodationTemplate from '../../design-system/accommodation-template'

const PAGE_SETTINGS = {
  title: 'Pistáciový pokoj',
  description: 'Dvoulůžkový pokoj s přistýlkou (1 lůžko) a vlastní koupelnou.',
}

const IMAGES = [
  'pokoje/pistaciovy/1.jpg',
  'pokoje/pistaciovy/2.jpg',
  'pokoje/pistaciovy/3.jpg',
  'pokoje/pistaciovy/4.jpg',
  'pokoje/pistaciovy/5.jpg',
  'pokoje/pistaciovy/6.jpg',
]

const TAB_SETTINGS = [
  {
    title: 'Cena',
    content: (
      <div>
        <ul>
          <li>2 200,- / noc</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Dispozice pokoje',
    content: (
      <div>
        <ul>
          <li>
            25m<sup>2</sup> plocha
          </li>
          <li>Ložnice</li>
          <li>Koupelna s vanou a WC</li>
          <li>Předsíň</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Vybavení pokoje',
    content: (
      <div>
        <ul>
          <li>Manželská postel + přistýlka 1 lůžko</li>
          <li>Klimatizace</li>
          <li>Minilednice</li>
          <li>TV s plochou obrazovkou</li>
          <li>Wifi</li>
          <li>Trezor</li>
          <li>Fén</li>
          <li>Povlečení, ručníky</li>
          <li>Rychlovarná konvice</li>
        </ul>
      </div>
    ),
  },
]

export function Pistachio() {
  return (
    <AccommodationTemplate
      images={IMAGES}
      tabSettings={TAB_SETTINGS}
      pageSettings={PAGE_SETTINGS}
    />
  )
}
