import styled from 'styled-components'
import BackToHomepageCross from '../../design-system/back-to-homepage-cross'
import CultureSection from '../../modules/culture'

const StyledContent = styled.div`
  padding: 6rem 0;
`

export function Culture() {
  return (
    <StyledContent>
      <BackToHomepageCross />
      <CultureSection />
    </StyledContent>
  )
}
