import styled from 'styled-components'
import BackToHomepageCross from '../../design-system/back-to-homepage-cross'
import AccommodationView from '../../modules/accommodation'

const StyledContent = styled.div`
  padding: 6rem 0;
`

export function Accommodation() {
  return (
    <StyledContent>
      <BackToHomepageCross />
      <AccommodationView />
    </StyledContent>
  )
}
