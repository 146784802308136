import AccommodationTemplate from '../../design-system/accommodation-template'

const PAGE_SETTINGS = {
  title: 'Vlašský pokoj',
  description:
    'Komfortní mezonetový apartmán s terasou, který se skládá z ložnice, obývacího pokoje s rozkládací pohovkou, koupelny a WC.',
}
const IMAGES = [
  'pokoje/vlassky/2.jpg',
  'pokoje/vlassky/5.jpg',
  'pokoje/vlassky/7.jpg',
  'pokoje/vlassky/13.jpg',
  'pokoje/vlassky/15.jpg',
  'pokoje/vlassky/9.jpg',
  'pokoje/vlassky/1.jpg',
  'pokoje/vlassky/6.jpg',
  'pokoje/vlassky/10.jpg',
  'pokoje/vlassky/11.jpg',
]

const TAB_SETTINGS = [
  {
    title: 'Cena',
    content: (
      <div>
        <ul>
          <li>2 600,- / noc</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Dispozice pokoje',
    content: (
      <div>
        <ul>
          <li>
            34m<sup>2</sup> plocha
          </li>
          <li>Obývací pokoj</li>
          <li>Ložnice</li>
          <li>Koupelna se sprchovým koutem a WC</li>
          <li>Samostatné WC</li>
          <li>Terasa</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Vybavení pokoje',
    content: (
      <div>
        <ul>
          <li>Manželská postel + přistýlka pro 2 osoby</li>
          <li>Klimatizace</li>
          <li>TV s plochou obrazovkou</li>
          <li>Wifi</li>
          <li>Trezor</li>
          <li>Fén</li>
          <li>Povlečení, ručníky</li>
          <li>Minilednice</li>
          <li>Rychlovarná konvice</li>
        </ul>
      </div>
    ),
  },
]

export function Walnut() {
  return (
    <AccommodationTemplate
      images={IMAGES}
      tabSettings={TAB_SETTINGS}
      pageSettings={PAGE_SETTINGS}
    />
  )
}
