import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import CrossIcon from '../cross-icon'

export function BackToHomepageCross() {
  const history = useHistory()
  const state: any = history.location.state

  return (
    <Link to={{ pathname: '/', state: state }}>
      <CrossIcon />
    </Link>
  )
}
