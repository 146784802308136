import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  children: ReactNode
}

const StyledToggler = styled.div<{ isShow: boolean }>`
  ${({ isShow }) =>
    isShow
      ? css`
          height: auto;
        `
      : css`
          height: calc(5 * 1.4rem);
          overflow: hidden;
        `}
`

const ToggleButton = styled.span`
  cursor: pointer;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`

export function Toggler(props: Props) {
  const [isShow, setIsShow] = useState(false)
  const { children } = props

  return (
    <>
      <StyledToggler isShow={isShow}>{children}</StyledToggler>
      {isShow ? (
        <ToggleButton onClick={() => setIsShow(false)}>skrýt</ToggleButton>
      ) : (
        <ToggleButton onClick={() => setIsShow(true)}>zobrazit více</ToggleButton>
      )}
    </>
  )
}
