import styled from 'styled-components'
import Image from '../../design-system/image'
import LinkButton from '../../design-system/link-button'
import Title from '../../design-system/title'

const StyledCafe = styled.div`
  display: flex;
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    width: 80%;

    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 825px) {
    width: 60%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`

const StyledCafeImage = styled(Image)`
  width: 100%;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 0px 4rem;

  p {
    text-align: justify;
  }

  @media only screen and (max-width: 825px) {
    grid-template-columns: 1fr;
    gap: 2rem 4rem;

    button {
      margin-bottom: 2rem;
    }
  }
`

export function Cafe() {
  return (
    <StyledCafe id="cafe-spaces">
      <Content>
        <div>
          <Title fontSize="3rem">Kafé Ořech</Title>
          <p>
            Místo, které jsme pro vás vytvořili ve spolupráci s architekty z ateliéru
            Archigroup srdcem a láskou. Je stvořeno pro váš skvělý start do nového dne u
            výběrové kávy od českých pražíren, kterou pro vás s péčí vybíráme a k ní naše
            lahodné dezerty pro energii do celého dne.
          </p>
          <LinkButton
            to={{ pathname: '/cafe-spaces', state: { idComingFromDiv: 'cafe-spaces' } }}
          >
            prostory Kafe Ořech
          </LinkButton>
        </div>
        <div>
          <StyledCafeImage src={'orech.jpg'} />
        </div>
      </Content>
    </StyledCafe>
  )
}
