import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  background: string
  title: string
  children: ReactNode
  link: string
}

const StyledRoomBox = styled.div<{ background: string }>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 250px;
  background: ${({ background }) =>
    css`linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${background})`};
  background-size: cover;
  background-position: center;
  flex-direction: column;
  box-sizing: border-box;
  transition: opacity 500ms ease-in;

  :hover {
    opacity: 0.5;
  }
`

const Description = styled.div`
  z-index: 9909;
  color: white;
  font-size: 1.1rem;
  padding: 1rem;

  h2 {
    font-family: 'Amatic SC';
    font-weight: 900;
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
    line-height: 2rem;
  }

  p {
    margin: 0;
  }
`

const RoomBoxLink = styled(Link)`
  text-decoration: none;
`

export function RoomBox(props: Props) {
  const { background, title, children, link } = props

  return (
    <RoomBoxLink to={link}>
      <StyledRoomBox background={background}>
        <Description>
          <h2>{title}</h2>
          {children}
        </Description>
      </StyledRoomBox>
    </RoomBoxLink>
  )
}
