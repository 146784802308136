import styled from 'styled-components'
import { CommoditiesItem } from './commodities-item'
import Image from '../../design-system/image'
import Toggler from '../../design-system/toggler'
import LinkButton from '../../design-system/link-button'

const coffeeCup = <Image src={'coffeeCup.svg'} />
const bed = <Image src={'bed.svg'} />
const hands = <Image src={'hands.svg'} />

const CommodityBox = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`

const StyledOffers = styled.div`
  width: 70%;
  margin: auto;
  margin-bottom: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 3rem;
  grid-template-areas: '. . .';

  @media only screen and (max-width: 1250px) {
    width: 80%;
  }

  @media only screen and (max-width: 1020px) {
    width: 85%;
  }

  @media only screen and (max-width: 870px) {
    width: 100%;
  }

  @media only screen and (max-width: 710px) {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 0;

    & > ${CommodityBox}:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  @media only screen and (max-width: 390px) {
    width: 100%;
  }
`

export function Commodities() {
  return (
    <StyledOffers id="commodities">
      <CommodityBox>
        <CommoditiesItem title="kavárna" icon={coffeeCup}>
          <Toggler>
            <p>
              Jsme příjemný a stylový prostor pro setkávání lidí. Posedíte si tu v klidné
              zahradě pod Ořechem, nebo uvnitř kavárny, která nabízí přes 30 míst k
              sezení. Najdete nás 100m od centra města Mnichovo Hradiště.
            </p>
          </Toggler>
        </CommoditiesItem>
        <LinkButton to={{ pathname: '/cafe', state: { idComingFromDiv: 'commodities' } }}>
          prohlédni nabídku
        </LinkButton>
      </CommodityBox>
      <CommodityBox>
        <CommoditiesItem title="ubytování" icon={bed}>
          <p>
            Spaní v Ořechu nabízí 4 komfortní pokoje ať už pro sladké nicnedělání,
            pracovní cestu či zastávku při cestování.
          </p>
        </CommoditiesItem>
        <LinkButton
          to={{ pathname: '/accommodation', state: { idComingFromDiv: 'commodities' } }}
        >
          vyber si pokoj
        </LinkButton>
      </CommodityBox>
      <CommodityBox>
        <CommoditiesItem title="kultura" icon={hands}>
          <p>
            Stodola přináší nový prostor pro divadelní či filmová představení, přednášky,
            školení, výstavy, koncerty, nebo jakékoliv oslavy a svatby.
          </p>
        </CommoditiesItem>
        <LinkButton
          to={{ pathname: '/culture', state: { idComingFromDiv: 'commodities' } }}
        >
          přijď se pobavit
        </LinkButton>
      </CommodityBox>
    </StyledOffers>
  )
}
