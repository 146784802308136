import { Splide as SplideWrap } from '@splidejs/react-splide'
import { useState } from 'react'
import styled from 'styled-components'
import Image from '../image'
import ImageModal from '../image-modal'
import MagnifierIcon from '../magnifier-icon'

const SPLIDE_SETTINGS = {
  type: 'slide',
  perPage: 3,
  focus: 'center',
  cover: true,
  heightRatio: 0.3,
  gap: '0.3rem',
  autoplay: true,
  height: 500,
  breakpoints: {
    900: {
      perPage: 2,
    },
    700: {
      perPage: 1,
    },
  },
}

const ImageDetail = styled(Image)`
  height: 60%;
`

const StyledSplideSlide = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  :hover {
    img {
      opacity: 1;
    }
  }
`

type Props = {
  images: string[]
  background?: 'white' | 'black'
}

export function Splide(props: Props) {
  const { images, background } = props
  const [imageDetail, setImageDetail] = useState<undefined | number>(undefined)

  return (
    <>
      <SplideWrap options={SPLIDE_SETTINGS}>
        {images.map((src, index) => {
          return (
            <StyledSplideSlide
              key={index}
              className={'splide__slide'}
              onClick={() => setImageDetail(index)}
            >
              <Image src={src} />
              <MagnifierIcon />
            </StyledSplideSlide>
          )
        })}
      </SplideWrap>
      {imageDetail !== undefined && (
        <ImageModal onClose={() => setImageDetail(undefined)} background={background}>
          <ImageDetail src={images[imageDetail]} />
        </ImageModal>
      )}
    </>
  )
}
