import Splide from '../splide'
import { Header } from './styles'
import Title from '../title'

type Props = {
  images: string[]
  title?: string
  background?: 'white' | 'black'
}

export function GalleryPageTemplate(props: Props) {
  const { images, title, background } = props

  return (
    <>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      <Splide images={images} background={background} />
    </>
  )
}
