import Splide from '../splide'
import { ReactNode } from 'react'
import { useCallback, useState } from 'react'
import {
  Header,
  BackLink,
  StyledArrow,
  HeaderLabel,
  Content,
  Tabs,
  TabTitle,
} from './styles'
import Title from '../title'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type TabType = {
  title: string
  content: ReactNode
}

type PageSettings = {
  title: string
  description: string
}

type Props = {
  pageSettings: PageSettings
  images: string[]
  tabSettings: TabType[]
}

const AccommodationTemplateWrap = styled.div`
  min-height: 1200px;
`

export function AccommodationTemplate({ images, tabSettings, pageSettings }: Props) {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabSwitch = useCallback((index: number) => {
    setActiveTab(index)
  }, [])

  return (
    <AccommodationTemplateWrap>
      <Header>
        <Link to="/accommodation">
          <BackLink>
            <StyledArrow src={'left-arrow.svg'} />
            <Title>zpět na ubytování</Title>
          </BackLink>
        </Link>
        <HeaderLabel>
          <Title>{pageSettings.title}</Title>
          <p>{pageSettings.description}</p>
        </HeaderLabel>
      </Header>
      <Splide images={images} />
      <Content>
        <Tabs>
          {tabSettings.map(({ title }, index) => {
            return (
              <TabTitle
                onClick={() => handleTabSwitch(index)}
                key={index}
                active={index === activeTab}
              >
                {title}
              </TabTitle>
            )
          })}
        </Tabs>

        {tabSettings[activeTab].content}
      </Content>
    </AccommodationTemplateWrap>
  )
}
