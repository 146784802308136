import styled, { css } from 'styled-components'
import Image from '../image'

type Props = {
  horizontal?: boolean
  className?: string
}

const StyledVerticalLine = styled(Image)<{ horizontal: boolean }>`
  width: auto;

  ${({ horizontal }) =>
    horizontal
      ? css`
          width: 200px;
        `
      : css`
          height: 200px;
        `}
`

const StyledDividerSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0;
`

export function DividerSection(props: Props) {
  const { horizontal = false, className } = props

  return (
    <StyledDividerSection className={className}>
      <StyledVerticalLine
        horizontal={horizontal}
        src={horizontal ? 'wave.svg' : 'down_arrow.svg'}
      />
    </StyledDividerSection>
  )
}
