import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import DividerSection from '../../design-system/divider-section'
import Cafe from '../../modules/cafe'
import Commodities from '../../modules/commodities'
import Footer from '../../modules/footer'
import ImageOverlaySection from '../../modules/image-overlay-section'
import Navigation from '../../modules/navigation'

const StyledHorizontalDivider = styled(DividerSection)`
  display: none;

  @media only screen and (max-width: 710px) {
    display: flex;
  }
`

export function Homepage() {
  const history = useHistory()

  useEffect(() => {
    // @ts-ignore
    const elementId = history.location.state?.idComingFromDiv

    if (elementId) {
      document.getElementById(String(elementId))?.scrollIntoView()
    }
    // @ts-ignore
  }, [history.location.state?.idComingFromDiv])

  return (
    <>
      <Navigation />
      <ImageOverlaySection />
      <Commodities />
      <StyledHorizontalDivider horizontal={true} />
      <Cafe />
      <DividerSection />
      <Footer />
    </>
  )
}
