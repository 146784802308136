import Splide from '../../design-system/splide'
import styled from 'styled-components'
import Title from '../../design-system/title'

const IMAGES = [
  'dobroty/14.jpg',
  'dobroty/2.jpg',
  'dobroty/4.jpg',
  'dobroty/6.jpg',
  'dobroty/7.jpg',
  'dobroty/10.jpg',
  'dobroty/11.jpg',
  'dobroty/23.jpg',
  'dobroty/24.jpg',
  'dobroty/25.jpg',
  'dobroty/15.jpg',
  'dobroty/12.jpg',
  'dobroty/17.jpg',
]

const StyledGallery = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    align-self: center;
  }

  @media only screen and (max-width: 1600px) {
    padding-top: 5rem;
  }

  @media only screen and (max-width: 845px) {
    padding-top: 4rem;
  }

  @media only screen and (max-width: 825px) {
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 790px) {
    padding-top: 1rem;
  }

  @media only screen and (max-width: 400px) {
    padding-top: 0;
  }
`

export function OurSweets() {
  return (
    <StyledGallery>
      <Title fontSize="3rem">Naše dobroty</Title>
      <Splide images={IMAGES} />
    </StyledGallery>
  )
}
