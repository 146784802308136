import { ReactNode } from 'react'
import styled from 'styled-components'
import Modal from '../modal'

type Props = {
  children: ReactNode
  background?: 'white' | 'black'
  onClose: () => void
}

const ImageDetailWrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function ImageModal(props: Props) {
  const { children, background, onClose } = props

  return (
    <Modal onClose={onClose} background={background}>
      <ImageDetailWrap>{children}</ImageDetailWrap>
    </Modal>
  )
}
