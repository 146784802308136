import styled from 'styled-components'
import Image from '../image'

type Props = {
  onClick?: () => void
}

const Cross = styled(Image)`
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 1rem 1rem auto auto;
  background: rgba(0, 0, 0, 0.4);
  padding: 0.5rem;
  transition: opacity 500ms;
  border-radius: 99999px;

  :hover {
    opacity: 0.5;
  }
`

export function CrossIcon(props: Props) {
  const { onClick } = props

  return <Cross src="cross_draw.svg" onClick={onClick} />
}
