import styled from 'styled-components'
import { OfferItem } from './offer-item'
import Image from '../../design-system/image'
import Toggler from '../../design-system/toggler'

const knifeAndFork = <Image src={'knifeAndFork.svg'} />
const drink = <Image src={'drink.svg'} />

const StyledOffers = styled.div`
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 3rem;
  padding: 8rem 4rem 0 4rem;
  grid-template-areas: '. .';

  @media only screen and (max-width: 1100px) {
    width: 80%;
    padding: 10rem 0 0 0;
  }

  @media only screen and (max-width: 870px) {
    padding: 10rem 0 0 0;
  }

  @media only screen and (max-width: 790px) {
    padding: 5rem 0 0 0;
  }

  @media only screen and (max-width: 650px) {
    margin: auto;
    width: 60%;
    grid-template-columns: 1fr;
    grid-template-areas: '.';
  }

  @media only screen and (max-width: 390px) {
    width: 100%;
  }
`

export function Offers() {
  return (
    <StyledOffers id="offers">
      <OfferItem title="jídelní lístek" icon={knifeAndFork}>
        <p>
          Naši denní nabídku snídaní, bruche, lehkého oběda, dezertů či jiných pochutin
          najdete aktuální na našich{' '}
          <a href="https://www.facebook.com/kafeorech/posts/644585696932237">
            facebookových
          </a>{' '}
          stránkách.
        </p>
      </OfferItem>
      <OfferItem title="napít se?" icon={drink}>
        <Toggler>
          <p>
            Pitný režim Vám zaručeně doplní Naše limonády, které si sami vyrábíme z našich
            surovin v návaznosti na sezónní nabídku. Ochutnejte u nás také lahodné čaje od
            firmy Mia Tea. Pohodový večer doplní lahodná vína z rodinného vinařství
            Volavkových či piva od lokálních pivovarů. A něco ostřejšího z naší nabídky
            míchaných nápojů či destilátů ? I&nbsp;to Vám připravíme s radostí a péčí.
          </p>
        </Toggler>
      </OfferItem>
    </StyledOffers>
  )
}
