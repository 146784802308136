import { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

type Props = {
  onClick?: () => void
  src: string
  className?: string
  widthLoader?: boolean
  withShadow?: boolean
}

const PlaceholderAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s ${PlaceholderAnimation} linear infinite;
`

const StyledImage = styled.img<{ withShadow: boolean }>`
  ${({ withShadow }) =>
    withShadow &&
    css`
      box-shadow: 5px 5px 15px 1px #bababa;
    `}
`

export function Image(props: Props) {
  const {
    src,
    onClick,
    className,
    widthLoader = false,
    withShadow = false,
    ...rest
  } = props
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      {isLoading && widthLoader && <ImagePlaceholder />}
      <StyledImage
        src={process.env.PUBLIC_URL + '/' + src}
        className={className}
        onLoad={() => setIsLoading(false)}
        withShadow={withShadow}
        onClick={onClick}
        {...rest}
      />
    </>
  )
}
