import { useState } from 'react'
import styled from 'styled-components'
import Image from '../image'
import ImageModal from '../image-modal'
import MagnifierIcon from '../magnifier-icon'
import Title from '../title'

type Props = {
  images: string[]
  title?: string
  background?: 'white' | 'black'
}

const PhotoTilesWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4px 4px;

  @media only screen and (max-width: 1040px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

const ImageWrap = styled.div<{ imageSrc: string }>`
  cursor: pointer;
  width: 100%;
  height: 300px;
  background: ${({ imageSrc }) => `url(${process.env.PUBLIC_URL}/${imageSrc})`};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    img {
      opacity: 1;
    }
  }
`

const ImageDetail = styled(Image)`
  height: 60%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export function PhotoTiles(props: Props) {
  const [showImageDetail, setShowImageDetail] = useState<undefined | string>(undefined)
  const { images, title, background } = props

  return (
    <>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      <PhotoTilesWrap>
        {images.map((image, index) => (
          <ImageWrap
            key={index}
            imageSrc={image}
            onClick={() => setShowImageDetail(image)}
          >
            <MagnifierIcon />
          </ImageWrap>
        ))}
      </PhotoTilesWrap>
      {showImageDetail && (
        <ImageModal onClose={() => setShowImageDetail(undefined)} background={background}>
          <ImageDetail src={showImageDetail} />
        </ImageModal>
      )}
    </>
  )
}
