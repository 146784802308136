import styled, { css } from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom'

type TAppearance = 'simple' | 'normal'

type Props = {
  appearance?: TAppearance
  href?: string
  children?: string
  className?: string
  withAnchor?: boolean
  onClick?: () => void
}

const StyledButton = styled.button<{ appearance?: TAppearance }>`
  cursor: pointer;
  font-family: 'Amatic SC';
  color: white;
  padding: 0.54rem 0.9rem;
  font-size: 2rem;

  ${({ appearance }) =>
    appearance === 'simple'
      ? css`
          border: 2px solid white;
          background-color: transparent;
        `
      : css`
          border: none;
          background-color: black;
        `}
`

export function Button(props: Props) {
  const {
    appearance = 'normal',
    children,
    href,
    className,
    withAnchor = false,
    onClick,
  } = props

  const button = (
    <StyledButton
      className={!href ? className : ''}
      appearance={appearance}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )

  if (href && withAnchor) {
    return (
      <AnchorLink className={className} href={href}>
        {button}
      </AnchorLink>
    )
  }

  if (href && !withAnchor) {
    return (
      <Link className={className} to={href}>
        {button}
      </Link>
    )
  }

  return button
}
