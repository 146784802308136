import styled, { css } from 'styled-components'

type Props = {
  appearance?: 'h1' | 'h2' | 'h3'
  withBg?: boolean
  children?: string
  className?: string
  fontSize?: string
  onClick?: () => void
}

const StyledTitle = styled.h1<{ withBg?: boolean; fontSize?: string }>`
  font-family: 'Amatic SC';
  font-size: ${({ fontSize }) => fontSize};
  width: fit-content;
  font-weight: 600;
  line-height: 2.8rem;

  ${({ withBg }) =>
    withBg
      ? css`
          color: white;
          padding: 1rem;
          background-color: black;
        `
      : css`
          color: black;
        `}
`

export function Title(props: Props) {
  const {
    appearance = 'h1',
    children,
    className,
    withBg = false,
    fontSize = '2rem',
    onClick,
  } = props

  return (
    <StyledTitle
      as={appearance}
      withBg={withBg}
      fontSize={fontSize}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledTitle>
  )
}
