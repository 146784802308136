import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'

type TAppearance = 'simple' | 'normal'

type Props = {
  appearance?: TAppearance
  to: LinkProps['to']
  children: ReactNode
}

const StyledLinkButton = styled(Link)<{ appearance?: TAppearance }>`
  cursor: pointer;
  font-family: 'Amatic SC';
  color: white;
  padding: 0.54rem 0.9rem;
  font-size: 2rem;
  line-height: normal;
  text-decoration: none;

  ${({ appearance }) =>
    appearance === 'simple'
      ? css`
          border: 2px solid white;
          background-color: transparent;
        `
      : css`
          border: none;
          background-color: black;
        `}
`

export function LinkButton(props: Props) {
  const { children, to } = props

  return <StyledLinkButton to={to}>{children}</StyledLinkButton>
}
