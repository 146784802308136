import AccommodationTemplate from '../../design-system/accommodation-template'

const PAGE_SETTINGS = {
  title: 'Lískový pokoj',
  description: 'Dvoulůžkový pokoj s výhledem do dvora kavárny s vlastní koupelnou.',
}

const IMAGES = ['pokoje/liskovy/1.jpg', 'pokoje/liskovy/2.jpg', 'pokoje/liskovy/3.jpg']

const TAB_SETTINGS = [
  {
    title: 'Cena',
    content: (
      <div>
        <ul>
          <li>1 900,- / noc</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Dispozice pokoje',
    content: (
      <div>
        <ul>
          <li>
            20m<sup>2</sup> plocha
          </li>
          <li>Ložnice</li>
          <li>Koupelna se sprchovým koutem a WC</li>
          <li>Předsíň</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Vybavení pokoje',
    content: (
      <div>
        <ul>
          <li>Manželská postel</li>
          <li>Klimatizace</li>
          <li>TV s plochou obrazovkou</li>
          <li>Wifi</li>
          <li>Trezor</li>
          <li>Fén</li>
          <li>Povlečení, ručníky</li>
          <li>Minilednice</li>
          <li>Rychlovarná konvice</li>
        </ul>
      </div>
    ),
  },
]

export function Hazelnut() {
  return (
    <AccommodationTemplate
      images={IMAGES}
      tabSettings={TAB_SETTINGS}
      pageSettings={PAGE_SETTINGS}
    />
  )
}
