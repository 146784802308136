import GalleryPageTemplate from '../../../design-system/gallery-page-template'

const IMAGES = [
  'stodola/12.jpg',
  'stodola/11.jpg',
  'stodola/2.jpg',
  'stodola/5.jpg',
  'stodola/3.jpg',
  'stodola/4.jpg',
  'stodola/9.jpg',
  'stodola/10.jpg',
]

export function BarnGallery() {
  return <GalleryPageTemplate images={IMAGES} title="fotky stodoly" />
}
