import { ReactNode } from 'react'
import styled from 'styled-components'
import CrossIcon from '../cross-icon'

type BackgroundType = 'white' | 'black'

type Props = {
  background?: BackgroundType
  children: ReactNode
  onClose: () => void
}

const StyledModal = styled.div<{ background: BackgroundType }>`
  position: fixed;
  z-index: 9999999;
  background: ${({ background }) =>
    background === 'white' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: scroll;
`

const CloseStripe = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 9999;
`

export function Modal(props: Props) {
  const { children, background = 'black', onClose } = props

  return (
    <StyledModal background={background} onClick={onClose}>
      <CloseStripe>
        <CrossIcon onClick={onClose} />
      </CloseStripe>
      {children}
    </StyledModal>
  )
}
