import styled from 'styled-components'
import DividerSection from '../../design-system/divider-section'
import Image from '../../design-system/image'
import Title from '../../design-system/title'
import { BarnGallery } from './components/barn-gallery'

const StyledCulture = styled.div`
  display: flex;
  width: 70%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    width: 80%;

    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 930px) {
    width: 100%;
  }

  @media only screen and (max-width: 825px) {
    width: 60%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`

const StyledCultureImage = styled(Image)`
  width: 100%;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 0px 4rem;

  a {
    color: black;
    font-weight: bold;
  }

  p {
    text-align: justify;
  }

  @media only screen and (max-width: 825px) {
    grid-template-columns: 1fr;

    button {
      margin-bottom: 2rem;
    }
  }
`

export function Culture() {
  return (
    <>
      <StyledCulture>
        <Content>
          <div>
            <Title fontSize="3rem">kultura ve stodole</Title>
            <p>
              V prostoru stodoly nabízíme různé možnosti zábavy. Sami organizujeme různá
              představení, tj. přednášky, školení, divadelni představení, koncerty,
              filmovou projekci, výstavy ajn. Náš prostor si bez obav může pronajmout pro
              své nápady. Pro divadelní či filmovou scénu máme k dispizici 75 míst k
              sezení. V případě zájmu o pronájem stodoly nás kontaktujte na&nbsp;
              <a href="mailto:hruskova@kafeorech.cz">hruskova@kafeorech.cz</a>
            </p>
            <p>
              Aktuální program můžete sledovat na našich{' '}
              <a href="https://www.facebook.com/kafeorech/events">facebookových</a>{' '}
              stránkách.
            </p>
          </div>
          <div>
            <StyledCultureImage src={'barn.jpg'} />
          </div>
        </Content>
      </StyledCulture>
      <DividerSection horizontal={true} />
      <BarnGallery />
    </>
  )
}
