import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class ScrollTo extends Component<RouteComponentProps> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props

    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

export default withRouter(ScrollTo)
