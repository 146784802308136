import styled from 'styled-components'
import Title from '../../design-system/title'
import RoomBox from './room-box'

const StyledAccommodation = styled.div`
  width: 80%;
  margin: auto;

  @media only screen and (max-width: 1250px) {
    h1 {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 700px) {
    width: 90%;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
  }
`

const RoomBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2rem;
  grid-template-areas: '. . . .';

  @media only screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .';
  }

  @media only screen and (max-width: 490px) {
    grid-template-columns: 1fr;
    grid-template-areas: '.';
  }
`

const MoreInfo = styled.div`
  margin-top: 4rem;

  a {
    text-decoration: underline;
    color: black;

    :hover {
      text-decoration: none;
    }
  }
`

export function Accommodation() {
  return (
    <StyledAccommodation>
      <Title fontSize="3rem">ubytování</Title>
      <RoomBoxes>
        <RoomBox
          background="pokoje/liskovy/2.jpg"
          title="Lískový pokoj"
          link="/accommodation/hazelnut"
        >
          <>
            <p>1 900,- / noc</p>
            <p>
              20m<sup>2</sup>
            </p>
          </>
        </RoomBox>
        <RoomBox
          background="pokoje/mandlovy/3.jpg"
          title="Mandlový pokoj"
          link="/accommodation/almond"
        >
          <>
            <p>2 400,- / noc</p>
            <p>
              37m<sup>2</sup>
            </p>
          </>
        </RoomBox>
        <RoomBox
          background="pokoje/pistaciovy/2.jpg"
          title="Pistáciový pokoj"
          link="/accommodation/pistachio"
        >
          <>
            <p>2 200,- / noc</p>
            <p>
              25m<sup>2</sup>
            </p>
          </>
        </RoomBox>
        <RoomBox
          background="pokoje/vlassky/3.jpg"
          title="Vlašský pokoj"
          link="/accommodation/walnut"
        >
          <>
            <p>2 600,- / noc</p>
            <p>
              34m<sup>2</sup>
            </p>
          </>
        </RoomBox>
      </RoomBoxes>

      <MoreInfo>
        <Title>Ostaní nabídka:</Title>
        <ul>
          <li>Snídaně nejsou v ceně</li>
          <li>
            Možnost <b>snídaně, lehkého oběda či večerního posezení</b> v naší kavárně.
            Viz otevírací doba Kafé Ořech.
          </li>
          <li>Snídani Vám rádi doneseme až na pokoj při včasném objednání</li>
          <li>Parkování zdarma</li>
        </ul>
        <b>
          Pokoje jsou nekuřácké. Nabízíme možnost ubytování se psem za příplatek 150,-Kč
          na noc.
        </b>
        <p>
          <a href={process.env.PUBLIC_URL + 'VOP.pdf'}>Všeobecné obchodní podmínky</a>
        </p>
      </MoreInfo>
    </StyledAccommodation>
  )
}
