import { ReactNode } from 'react'
import styled from 'styled-components'
import Title from '../../design-system/title'

type Props = {
  icon: ReactNode
  title: string
  children?: ReactNode
}

const StyledCommodityItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 320px;
  flex-grow: 1;

  a {
    color: black;
  }

  @media only screen and (max-width: 650px) {
    margin-bottom: 1rem;
    min-height: unset;
  }

  @media only screen and (max-width: 350px) {
    padding: 1rem;
  }
`

const StyledTitle = styled(Title)`
  align-self: center;
  white-space: nowrap;

  @media only screen and (max-width: 650px) {
    margin-bottom: 0;
    min-height: unset;
  }
`

const Icon = styled.div`
  text-align: center;

  & > * {
    height: 60px;
  }
`

const Children = styled.div`
  p {
    text-align: justify;
  }
`

export function CommoditiesItem(props: Props) {
  const { icon, title, children } = props

  return (
    <StyledCommodityItem>
      <Icon>{icon}</Icon>
      <StyledTitle fontSize="3rem">{title}</StyledTitle>
      <Children>{children}</Children>
    </StyledCommodityItem>
  )
}
