import { ReactNode } from 'react'
import styled from 'styled-components'
import Title from '../../design-system/title'

type Props = {
  icon: ReactNode
  title: string
  children?: ReactNode
}

const StyledOfferItem = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: black;
    font-weight: bold;
  }

  @media only screen and (max-width: 650px) {
    margin-bottom: 3rem;
  }
`

const StyledTitle = styled(Title)`
  align-self: center;
  white-space: nowrap;
`

const Icon = styled.div`
  text-align: center;

  & > * {
    height: 60px;
  }
`

const Children = styled.div`
  p {
    text-align: justify;
  }
`

export function OfferItem(props: Props) {
  const { icon, title, children } = props

  return (
    <StyledOfferItem>
      <Icon>{icon}</Icon>
      <StyledTitle fontSize="3rem">{title}</StyledTitle>
      <Children>{children}</Children>
    </StyledOfferItem>
  )
}
